
import * as myProviderRuntime$PK8JHCodKw from 'D:/GitLab-Runner/build/gV_R9zkRE/0/SmookItalia/smookitalia-nuxt-app/providers/base-provider.ts'
import * as ipxRuntime$YcX2cWzkCT from 'D:/GitLab-Runner/build/gV_R9zkRE/0/SmookItalia/smookitalia-nuxt-app/node_modules/@nuxt/image/dist/runtime/providers/ipx'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "ipx",
  "domains": [
    "smookitalia-acceptatie-api.tc8l.dev"
  ],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp",
    "webp"
  ]
}

imageOptions.providers = {
  ['baseProvider']: { provider: myProviderRuntime$PK8JHCodKw, defaults: undefined },
  ['ipx']: { provider: ipxRuntime$YcX2cWzkCT, defaults: {} }
}
        